body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: 100%;
}

body {
    min-height: 100vh;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    font-family: "Rubik";
}

#pageContainer {
    min-height: "100%";
    display: "block";
    flex-grow: 1;
    display: flex;
}

.material-symbols-rounded {
    font-family: "Material Symbols Rounded";
    font-size: 1em;
    vertical-align: -0.12em;
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24
}

.material-symbols-rounded.fill {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24
}

.material-symbols-rounded.lite {
    font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 24
}